<template>
    <div>
        <div v-if="!$apollo.queries.get_hackathon_hackathon.loading">
            <div class="header pb-6 d-flex align-items-center">
                <!-- Mask -->
                <span class="mask bg-white opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex">
                    <b-row>
                        <!-- <b-col sm="12" md="auto" class="mt-4">
                            <div
                                @click="$router.back()"
                                class="icon icon-shape rounded-circle shadow bg-primary text-white"
                            >
                                <i class="fa fa-arrow-left"></i>
                            </div>
                        </b-col> -->
                        <!-- Hackathon name -->
                        <b-col sm="12" md="auto" class="mt-2">
                            <b-row>
                                <b-col>
                                    <h1
                                        class="text-primary display-1 mb--2"
                                        v-if="hackathon_id"
                                    >
                                        {{ hackathon.name }}
                                    </h1>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <small class="text-muted"
                                        >By: {{ hackathon.organization.name }}
                                    </small>
                                    <small
                                        class="text-muted"
                                        v-if="
                                            hackathon.management_team_visibile
                                        "
                                        >(<a
                                            :href="
                                                'mailto:' +
                                                hackathon.creator.email
                                            "
                                            >{{ hackathon.creator.name }}</a
                                        >)</small
                                    >
                                </b-col>
                            </b-row>
                        </b-col>
                        <!-- Edit button -->
                        <b-col class="text-right mt-4 pt-3">
                            <h2>
                                <router-link
                                    v-if="
                                        hackathon.is_creator ||
                                        hackathon.is_manager
                                    "
                                    :to="{
                                        path: `/hackathon/${encodeURIComponent(
                                            hackathon_id
                                        )}/timeline`,
                                    }"
                                >
                                    <i class="fas fa-edit"></i>
                                </router-link>
                            </h2>
                        </b-col>

                        <b-col sm="6" md="auto" class="mt-4 pt-2 text-right">
                            <RegisterButton
                                :hackathon_id="hackathon_id"
                            ></RegisterButton>
                        </b-col>
                        <b-col
                            class="text-right mt-4 pt-3"
                            v-if="
                                hackathon.is_creator ||
                                hackathon.is_manager ||
                                hackathon.allowed_participants == 'public'
                            "
                        >
                            <el-tooltip
                                content="Copy link for hackathon."
                                placement="top"
                            >
                                <h2>
                                    <i
                                        @click="
                                            copy_to_clipboard(
                                                get_hackathon_share_url()
                                            )
                                        "
                                        class="fas fa-share-alt text-primary"
                                    ></i>
                                </h2>
                            </el-tooltip>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <b-container fluid class="mt--6">
                <!-- Hackathon Details -->
                <b-row>
                    <b-col>
                        <p v-html="hackathon.detail_description"></p>
                    </b-col>
                </b-row>

                <b-row class="mt-4">
                    <!-- Prizes -->
                    <b-col sm="12" md="6" v-if="hackathon.has_prizes">
                        <b-card>
                            <b-row>
                                <b-col>
                                    <PrizesWidget
                                        :hackathon_id="hackathon_id"
                                    />
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <!-- Goals -->
                    <b-col sm="12" md="6" v-if="hackathon.has_goals">
                        <b-card>
                            <b-row>
                                <b-col>
                                    <GoalsWidget :hackathon_id="hackathon_id" />
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>

                <!-- Ideas -->
                <b-row
                    class="mt-4"
                    v-if="!$apollo.queries.get_all_hackathon_ideas.loading"
                >
                    <b-col sm="12">
                        <b-row class="mb-4">
                            <b-col sm="auto">
                                <h1 class="display-3 text-primary pb-0 mb-0">
                                    Ideas
                                </h1>
                            </b-col>
                            <!-- Search -->
                            <b-col sm="3" class="pr-0">
                                <b-form
                                    class="navbar-search form-inline navbar-search-light"
                                    id="navbar-search-main"
                                    @submit.prevent="on_search_submit"
                                >
                                    <b-form-group class="mb-0">
                                        <b-input-group
                                            class="input-group-alternative input-group-merge"
                                        >
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"
                                                    ><i
                                                        class="fas fa-search"
                                                    ></i
                                                ></span>
                                            </div>
                                            <b-form-input
                                                placeholder="Search"
                                                type="text"
                                                v-model="search.search_term"
                                                @keyup="on_keyup_search"
                                            >
                                            </b-form-input>

                                            <input
                                                type="submit"
                                                ref="search_form_submit"
                                                hidden
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                    <Transition>
                                        <i
                                            v-if="search.is_loading"
                                            class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"
                                        ></i>
                                    </Transition>
                                    <button
                                        type="button"
                                        class="close"
                                        data-action="search-close"
                                        data-target="#navbar-search-main"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </b-form>
                            </b-col>
                            <!-- Plus Button -->
                            <b-col class="text-right">
                                <el-tooltip
                                    content="Submit an Idea"
                                    placement="bottom"
                                >
                                    <router-link
                                        :to="{
                                            name: 'HackathonCrIdea',
                                            params: {
                                                hackathon_id: hackathon_id,
                                            },
                                        }"
                                        class="icon icon-shape rounded-circle shadow bg-primary text-white"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </router-link>
                                </el-tooltip>
                            </b-col>
                        </b-row>
                        <!-- Hackathon Idea Cards -->

                        <b-row v-if="filtered_hackathon_ideas.length > 0">
                            <HackathonListIdeaCard
                                v-for="idea in filtered_hackathon_ideas"
                                :key="idea.id"
                                :id="idea.id"
                                :hackathon_id="hackathon_id"
                                :name="idea.name"
                                :description="idea.description"
                                :likes="idea.number_of_likes"
                                :liked_id="idea.liked_id"
                                :is_creator="idea.is_creator"
                                :team="idea.team"
                                :is_part_of_team="is_part_of_team"
                                @liked="idea.number_of_likes++"
                                @new_like_id="
                                    (id) => {
                                        idea.liked_id = id;
                                    }
                                "
                                @unliked="idea.number_of_likes--"
                            />
                        </b-row>
                        <b-row
                            v-else-if="
                                filtered_hackathon_ideas.length < 1 &&
                                hackathon_ideas.length > 0
                            "
                        >
                            <b-col class="text-center">
                                <h3 class="text-muted">
                                    No ideas found matching your search
                                </h3>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col class="text-center">
                                <h3 class="text-muted">
                                    No ideas submitted yet, use the '+' button
                                    to submit an idea.
                                </h3>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="text-center" v-else>
                    <b-col>
                        <h1>
                            <i class="fas fa-spinner fa-spin ml-1"></i>
                        </h1>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <b-row class="text-center">
                <b-col>
                    <h1>
                        <i class="fas fa-spinner fa-spin ml-1"></i>
                    </h1>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { debounce } from "debounce";

// Components
import HackathonListIdeaCard from "@/views/Components/Hackathon/Idea/ListIdeaCard.vue";
import PrizesWidget from "@/views/Components/Hackathon/Prizes/PrizesWidget.vue";
import GoalsWidget from "@/views/Components/Hackathon/Goals/GoalsWidget.vue";
import RegisterButton from "@/views/Components/Hackathon/Participants/RegisterButton.vue";

// Queries
import {
    GET_HACKATHON_IDEAS,
    GET_HACKATHON_HACKATHON,
    GET_HACKATHON_PROFILE_FROM_USER_ID,
} from "@/graphql/queries";

// Mutations
import { CREATE_HACKATHON_PARTICIPANT } from "@/graphql/mutations";

// ORM
import {
    processHackathonRelayData,
    Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

export default {
    name: "ViewHackathon",
    components: {
        HackathonListIdeaCard,
        PrizesWidget,
        GoalsWidget,
        RegisterButton,
    },
    apollo: {
        get_hackathon_hackathon: {
            query: GET_HACKATHON_HACKATHON,
            result(res) {
                this.hackathon = processHackathonRelayData(res);
                this.activate_hackathon();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_hackathon = data;
            },
            skip: true,
        },
        get_all_hackathon_ideas: {
            query: GET_HACKATHON_IDEAS,
            result(res) {
                let flat_res = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(res)
                );
                this.hackathon_ideas = [];
                flat_res.data__allHackathonIdeaIdea.forEach((el) => {
                    let hackathon_idea = {
                        id: el.id,
                        name: el.name,
                        description: el.description,
                        detail_description: el.detailDescription,
                        number_of_likes: el.numberOfLikes,
                        created: new Date(el.created),
                        is_creator: el.isCreator,
                        liked_id: null,
                    };
                    // if (el.likes.length > 0) {
                    //     hackathon_idea.liked_id = el.likes[0].id;
                    // }
                    if ("team__id" in el) {
                        hackathon_idea.team = {
                            member_count: el.team__totalMembers,
                            max_members: el.hackathon__maxTeamSize,
                            is_member: el.team__isMember,
                        };
                        if (el.team__isMember) {
                            this.is_part_of_team = true;
                        }
                    } else {
                        hackathon_idea.team = {
                            member_count: 0,
                            max_members: el.hackathon__maxTeamSize,
                            is_member: false,
                        };
                    }
                    this.hackathon_ideas.push(hackathon_idea);
                });
                this.filtered_hackathon_ideas = this.hackathon_ideas;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                    user_id: store.getters.getUser.id_b64,
                };
            },
            update(data) {
                this.apollo_data.get_all_hackathon_ideas = data;
            },
            skip: true,
        },
        get_hakcathon_profile: {
            query: GET_HACKATHON_PROFILE_FROM_USER_ID,
            result(res) {
                let profiles = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonProfileProfile
                    )
                );
                if (profiles.length > 0) {
                    this.hackathon_profile.id = profiles[0].id;
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    user: store.getters.getUser.id_b64,
                };
            },
            update(data) {
                this.apollo_data.get_hakcathon_profile = data;
            },
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_hackathon: {},
                get_all_hackathon_ideas: {},
                get_hakcathon_profile: {},
            },
            hackathon_id: "null",
            hackathon: {
                name: null,
                description: null,
                detail_description: null,
                is_participant: false,
                is_manager: false,
                is_creator: false,
                management_team_visibile: false,
                creator: {
                    name: null,
                },
                organization: {
                    name: null,
                },
                has_goals: false,
                has_prizes: false,
            },
            hackathon_profile: {
                id: null,
            },
            hackathon_ideas: [],
            filtered_hackathon_ideas: [],
            is_part_of_team: false,

            search: {
                search_term: "",
                is_loading: false,
            },
        };
    },
    methods: {
        // Search Functionality

        on_keyup_search() {
            this.search.is_loading = true;
            this.debounced_on_keyup_search();
        },

        debounced_on_keyup_search: debounce(function () {
            this.$refs.search_form_submit.click();
        }, 1000),
        on_search_submit() {
            if (this.search.search_term === "") {
                this.filtered_hackathon_ideas = this.hackathon_ideas;
                setTimeout(() => {
                    this.search.is_loading = false;
                }, 500);
            } else {
                this.filtered_hackathon_ideas = this.filter_hackathon_ideas(
                    this.search.search_term,
                    this.hackathon_ideas
                );
                this.search.is_loading = false;
            }
        },

        filter_hackathon_ideas(search_term, in_array) {
            let out_array = in_array.filter((item) => {
                if (item.name === undefined) {
                    item.name = "";
                }
                if (item.detail_description === undefined) {
                    item.detail_description = "";
                }

                return (
                    item.name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.description
                        .toLowerCase()
                        .includes(search_term.toLowerCase())
                );
            });
            return out_array;
        },

        // Apollo Methods
        enable_query(query) {
            if (!query.skip) {
                query.refetch();
            } else {
                query.skip = false;
                query.start();
            }
        },

        // ID Management
        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
                this.enable_query(this.$apollo.queries.get_hackathon_hackathon);
                this.enable_query(this.$apollo.queries.get_all_hackathon_ideas);
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },

        getUser() {
            this.int_user = utils.deepcopy(this.$store.getters.getUser);
        },
        // Apollo Methods
        handleAllIdeaIdea(data) {
            this.ideasList = [];
            data.forEach((i) => {
                this.ideasList.push({
                    name: i.node.name,
                    description: i.node.description,
                    detail_description: i.node.detailDescription,
                    id: i.node.id,
                    team: [],
                    like_count: i.node.numberOfLikes,
                    creator_id: i.node.creator.id,
                });
            });
        },

        activate_hackathon() {
            store.commit("setActiveHackathon", {
                id: this.hackathon_id,
                name: this.hackathon.name,
            });
            global_event_emitter.$emit("hackathon_sidebar_reload_required");
        },

        async copy_to_clipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.$notify({
                    message: `Copied to clipboard`,
                    timeout: 1000,
                    icon: "ni ni-check-bold",
                    type: "success",
                });
            } catch ($e) {
                console.log($e);
            }
        },
        get_hackathon_share_url() {
            return (
                window.location.origin +
                "/#/pub/hackathon/" +
                encodeURIComponent(this.hackathon_id) +
                "/detail?token=" +
                this.hackathon.uid +
                "&particpant_invite=true"
            );
        },
    },

    mounted() {
        this.get_hackathon_id();
    },
    created() {
        this.get_hackathon_id();
    },
};
</script>

<style></style>
