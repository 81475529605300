<template>
    <div>
        <!-- #region Modals -->
        <b-modal
            id="manage_registration_modal"
            :title="
                registered
                    ? 'Manage Registration'
                    : 'Register for: ' + hackathon.name
            "
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col v-if="!registered">
                        By clicking on <strong>Register</strong> you will become
                        part of the: <br />
                        <strong
                            >'{{ hackathon.name }}{{
                                hackathon.name
                                    .toLowerCase()
                                    .includes("hackathon")
                                    ? "'."
                                    : "' hackathon."
                            }}
                        </strong>
                    </b-col>
                    <b-col v-else>
                        By clicking on '<strong>Unregister</strong>' you will
                        <strong>leave</strong>: <br /><strong
                            >'
                            {{ hackathon.name }}
                            {{
                                hackathon.name
                                    .toLowerCase()
                                    .includes("hackathon")
                                    ? "'."
                                    : "' hackathon."
                            }}
                        </strong>
                        <br />
                        You may not be able to re-register.
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('manage_registration_modal')"
                            :disabled="modals.manage_registration_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            @click="
                                handle_register_unregister(
                                    registered ? 'withdraw' : 'register'
                                )
                            "
                            :disabled="modals.manage_registration_modal.loading"
                            :loading="modals.manage_registration_modal.loading"
                            :success="modals.manage_registration_modal.success"
                            >{{ registered ? "Unregister" : "Register" }}
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!-- #endregion -->
        <!-- #region Button -->
        <b-row>
            <b-col>
                <b-button
                    v-if="!$apollo.loading"
                    :pill="true"
                    :variant="registered ? 'default' : 'info'"
                    size="md"
                    type="default"
                    @click="$bvModal.show('manage_registration_modal')"
                >
                    {{ registered ? "Unregister" : "Register" }}
                </b-button>

                <i v-else class="fas fa-spinner fa-spin mt-2 text-muted"></i>
            </b-col>
        </b-row>
        <!-- #endregion -->
    </div>
</template>

<script>
// Queries
import { GET_HACKATHON_HACKATHON_REGISTER_BUTTON } from "@/graphql/queries";
// Mutations
import {
    CREATE_HACKATHON_PARTICIPANT,
    UPDATE_HACKATHON_PARTICIPANT,
} from "@/graphql/mutations";

export default {
    name: "RegisterButton",
    props: {
        hackathon_id: {
            type: String,
            description: "The id of the hackathon",
            default: null,
        },
    },
    apollo: {
        get_hackathon: {
            query: GET_HACKATHON_HACKATHON_REGISTER_BUTTON,
            result(res) {
                this.hackathon.name = res.data.hackathonHackathon.name;
                this.registered = res.data.hackathonHackathon.isParticipant;
                this.participant_id =
                    res.data.hackathonHackathon.myParticipantId;
                this.hackathon.status = res.data.hackathonHackathon.status;
                this.is_banned = res.data.hackathonHackathon.isBanned;

                if (this.$route.query.register) {
                    if (!this.registered) {
                        this.$bvModal.show("manage_registration_modal");
                    }
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon: {},
            },
            modals: {
                manage_registration_modal: {
                    loading: false,
                    success: false,
                },
            },
            hackathon: {
                name: null,
                status: null,
            },
            hackathon: {
                name: "Test Hackathon",
            },

            registered: false,
            participant_id: null,
            is_banned: false,
        };
    },
    methods: {
        //#region Modals

        handle_register_unregister(action) {
            // action can be 'register' or 'withdraw'

            let new_status;
            if (action === "register") {
                new_status = "REGISTERED";
            } else if (action === "withdraw") {
                new_status = "WITHDRAWN";
            } else {
                this.$notify({
                    message: `Something went wrong.`,
                    timeout: 5000,
                    icon: "ni ni-check-bold",
                    type: "warning",
                });
                return;
            }

            if (this.participant_id === null) {
                this.create_participant(new_status);
            } else {
                this.update_participant(new_status);
            }
        },

        create_participant(new_status) {
            this.modals.manage_registration_modal.loading = true;

            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON_PARTICIPANT,
                    variables: {
                        hackathon_id: this.hackathon_id,
                        status: new_status,
                    },
                })
                .then((res) => {
                    this.modals.manage_registration_modal.success = true;
                    setTimeout(() => {
                        this.modals.manage_registration_modal.success = false;
                    }, 1000);

                    this.modals.manage_registration_modal.loading = false;

                    this.$notify({
                        message: `You have registered for the '${this.hackathon.name}' Hackathon`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });

                    setTimeout(() => {
                        this.$bvModal.hide("manage_registration_modal");
                        this.registered = true;
                    }, 1000);
                    this.hackathon.is_participant = true;
                    this.participant_id =
                        res.data.hackathonParticipantCreateParticipant.participant.id;

                    setTimeout(() => {
                        this.activate_hackathon();
                    }, 500);
                })
                .catch((err) => {
                    this.modals.manage_registration_modal.loading = false;
                    if (String(err).includes("UNIQUE constraint failed")) {
                        this.modals.manage_registration_modal.success = true;
                        setTimeout(() => {
                            this.modals.manage_registration_modal.success = false;
                        }, 1000);
                        this.$notify({
                            message: `You are already registered for this hackathon.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.hackathon.is_participant = true;
                    } else {
                        this.$notify({
                            message: `Could not register you for the '${this.hackathon.name}' Hackathon`,
                            timeout: 5000,
                            icon: "ni ni-fat-remove",
                            type: "warning",
                        });
                        console.log(err);
                    }

                    this.$apollo.queries.get_hackathon.refetch();
                });
        },
        update_participant(new_status) {
            this.modals.manage_registration_modal.loading = true;

            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_PARTICIPANT,
                    variables: {
                        participant_id: this.participant_id,
                        hackathon_id: this.hackathon_id,
                        status: new_status,
                    },
                })
                .then(() => {
                    this.modals.manage_registration_modal.success = true;
                    setTimeout(() => {
                        this.modals.manage_registration_modal.success = false;
                    }, 1000);

                    this.modals.manage_registration_modal.loading = false;

                    this.$notify({
                        message: `You have succefulluy updated your registration for the '${this.hackathon.name}' Hackathon`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });

                    setTimeout(() => {
                        this.$bvModal.hide("manage_registration_modal");
                        if (new_status == "WITHDRAWN") {
                            this.registered = false;
                            setTimeout(() => {
                                this.activate_hackathon();
                            }, 500);
                        } else if (new_status == "REGISTERED") {
                            this.registered = true;
                        }
                    }, 500);
                })
                .catch((err) => {
                    this.modals.manage_registration_modal.loading = false;
                    if (String(err).includes("UNIQUE constraint failed")) {
                        this.modals.manage_registration_modal.success = true;
                        setTimeout(() => {
                            this.modals.manage_registration_modal.success = false;
                        }, 1000);
                        this.$notify({
                            message: `You are already registered for this hackathon.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.hackathon.is_participant = true;
                    } else {
                        this.$notify({
                            message: `Could not update your registration for:'${this.hackathon.name}'`,
                            timeout: 5000,
                            icon: "ni ni-fat-remove",
                            type: "warning",
                        });
                        console.log(err);
                    }

                    this.$apollo.queries.get_hackathon.refetch();
                });
        },

        registration_confirmation() {
            this.modals.manage_registration_modal.loading = true;

            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON_PARTICIPANT,
                    variables: {
                        hackathon_id: this.hackathon_id,
                    },
                })
                .then(() => {
                    this.modals.manage_registration_modal.success = true;
                    this.modals.manage_registration_modal.loading = false;

                    this.$notify({
                        message: `You have registered for the '${this.hackathon.name}' Hackathon`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                    setTimeout(() => {
                        this.modals.manage_registration_modal.success = false;
                    }, 1000);

                    setTimeout(() => {
                        this.$bvModal.hide("manage_registration_modal");
                    }, 1000);
                    this.hackathon.is_participant = true;

                    setTimeout(() => {
                        this.activate_hackathon();
                    }, 500);
                })
                .catch((err) => {
                    this.modals.manage_registration_modal.loading = false;
                    if (String(err).includes("UNIQUE constraint failed")) {
                        this.modals.manage_registration_modal.success = true;
                        setTimeout(() => {
                            this.modals.manage_registration_modal.success = false;
                        }, 1000);
                        this.$notify({
                            message: `You are already registered for this hackathon.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.hackathon.is_participant = true;
                    } else {
                        this.$notify({
                            message: `Could not register you for the '${this.hackathon.name}' Hackathon`,
                            timeout: 5000,
                            icon: "ni ni-fat-remove",
                            type: "warning",
                        });
                        console.log(err);
                    }

                    setTimeout(() => {
                        this.$bvModal.hide("manage_registration_modal");
                    }, 1000);
                });
        },

        //#endregion

        activate_hackathon() {
            store.commit("setActiveHackathon", {
                id: this.hackathon_id,
                name: this.hackathon.name,
            });
            global_event_emitter.$emit("hackathon_sidebar_reload_required");
        },

        // Query management
        manage_get_hackahton() {
            if (this.hackathon_id) {
                graph_utils.enable_query(this.$apollo.queries.get_hackathon);
            } else {
                graph_utils.disable_query(this.$apollo.queries.get_hackathon);
            }
        },
    },
    mounted() {
        this.manage_get_hackahton();
    },

    watch: {
        hackathon_id() {
            this.manage_get_hackahton();
        },
    },
};
</script>

<style></style>
